<template>
  <div>
    <b-card class="mt-4" v-if="(campaignSelected_texts = '')">
      <b-tabs
        content-class="p-4 content-border"
        nav-class="people-analysis-tabs"
      >
        <b-tab lazy ref="activeOnLoad">
          <template #title>
            <title-list
              @CustomEvent_FormChange="changeSelected"
              :options="landingOptions"
              :selected="landingSelected"
              :items="landingItems"
              target="landingSelected"
            ></title-list>
          </template>
          <div>
            <div class="mt-4 mb-4 d-flex charts-container w-100">
              <simple-pie-chart
                class="w-100"
                :labels="['Scroll Completed', 'Views']"
                :pieData="[22, 100]"
                :legend="['Scroll Completed ' + 22, 'Views ' + 100]"
              ></simple-pie-chart>
              <simple-pie-chart
                class="w-100"
                :labels="['AVG Time', 'X sec.']"
                :pieData="[12, 100]"
                :legend="['AVG Time ' + 12, 'X sec. ' + 100]"
              ></simple-pie-chart>
            </div>
            <div class="d-flex">
              <line-chart
                class="w-100 line-chart"
                :chartData="lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="landingSelected"
              /><line-chart
                class="w-100 line-chart"
                :chartData="cumulative_lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="'Cumulativo: ' + landingSelected"
              />
            </div>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <title-list
              @CustomEvent_FormChange="changeSelected"
              :options="SocialOptions"
              :selected="SocialSelected"
              :items="SocialItems"
              target="SocialSelected"
            ></title-list>
          </template>
          <div>
            <!-- <div class="mt-4 mb-4 d-flex">
              <title-pie
                class="w-100"
                :labels="['Scroll Completed', 'Views']"
                :pieData="[22, 100]"
                title="Engagement score landing"
                caption="Engagement score Landing"
              ></title-pie>
              <title-pie
                class="w-100"
                :labels="['AVG Time', 'X sec.']"
                :pieData="[12, 100]"
                title="Engagement score landing"
                caption="Engagement score Landing"
              ></title-pie>
            </div>
            <div class="d-flex">
              <line-chart
                class="w-100 line-chart"
                :chartData="lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="landingSelected"
              /><line-chart
                class="w-100 line-chart"
                :chartData="cumulative_lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="'Cumulativo: ' + landingSelected"
              />
            </div> -->
          </div>
          <!-- <div class="tab-container">
            <line-chart :chartData="data" :options="chartOptions" />
          </div> -->
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card class="mt-4" v-else>{{ campaignSelected_text }}</b-card>
  </div>
</template>

<script>
// import AxiosService from "../axiosServices/AxiosService";
import LineChart from "../LineChart.vue";
import SimplePieChart from "../SimplePieChart.vue";
import TitleList from "../DashBoard/TabTitles/TitleList.vue";
import { mapGetters } from "vuex";

export default {
  name: "SocialShareComponent",
  components: {
    LineChart,
    TitleList,
    SimplePieChart,
  },
  props: {
    // filter: Object,
    // dataSocialShare: Object,
    showGraph: Boolean,
    campaignSelected_text: String,
  },
  service: null,
  created() {
    this.view_lang = this.default_lang.value;
  },

  mounted() {
    console.log(this.dataSocialShare);
    this.loadData();
  },
  data() {
    return {
      dataSocialShare: {
        landing: [
          {
            text: "landing page",
            value: 1,
            views: 10,
            scrollCompleted: 3,
            compiledForm: 2,
            formStarted: 3,
          },
          {
            text: "landing page 2",
            value: 2,
            views: 59,
            scrollCompleted: 56,
            compiledForm: 30,
            formStarted: 41,
          },
        ],
        socials: [
          {
            text: "facebook",
            value: 1,
            pubblished: 20,
            views: 18,
            converted: 7,
          },
          {
            text: "linkedin",
            value: 2,
            pubblished: 19,
            views: 12,
            converted: 2,
          },
        ],
      },

      landingSelected: "All landings",
      landingOptions: [{ text: "All landings", value: "All landings" }],

      pieData: {
        sectionSelected: [0, 0],
        chapterSelected: [0, 0],
        correctAnswers: [0, 0],
      },

      SocialSelected: "All socials",
      SocialOptions: [{ text: "All socials", value: "All socials" }],

      lineChartData: [1, 3, 2, 3, 1, 4, 2, 4],
      lineChartLabels: ["1", "2", "3", "4", "5", "6", "7", "8"],

      view_lang: null,

      landingItems: [
        {
          text: "Views",
          value: 10,
        },
        {
          text: "Scroll Completed",
          value: 3,
        },
        {
          text: "AVG Time",
          value: 12,
        },
        {
          text: "Compiled forms",
          value: 2,
        },
        {
          text: "Forms started",
          value: 3,
        },
      ],
      SocialItems: [
        {
          text: "Pubblished",
          value: 43,
        },
        {
          text: "Views",
          value: 31,
        },
        {
          text: "Converted",
          value: 12,
        },
      ],
    };
  },

  methods: {
    loadData() {
      // this.dataSocialShare.landing.length = 1;
      // this.dataSocialShare.socials.length = 1;

      this.dataSocialShare.landing.forEach((element) => {
        this.landingOptions.push(element);
      });
      this.dataSocialShare.socials.forEach((element) => {
        this.SocialOptions.push(element);
      });
    },
    changeSelected(event) {
      this[event.target] = event.value;

      switch (event.target) {
        case "sectionSelected":
          this.pieData.sectionSelected.length = 0;
          this.pieData.sectionSelected.push(event.element.capitoliCompletati);
          this.pieData.sectionSelected.push(event.element.capitoliTotali);
          this.chaptersOptions.length = 1;
          this.chapterSelected = "All chapters";
          this.pieData.chapterSelected = [0, 0];
          this.pieData.correctAnswers = [0, 0];
          break;
        case "chapterSelected":
          this.pieData.chapterSelected.length = 0;
          this.pieData.chapterSelected.push(event.element.domandeRisposte);
          this.pieData.chapterSelected.push(event.element.domandeTotali);
          this.pieData.correctAnswers.length = 0;
          this.pieData.correctAnswers.push(event.element.domandeCorrette);
          this.pieData.correctAnswers.push(event.element.domandeRisposte);
          break;
      }

      event.element.chapters.forEach((element) => {
        element.text = element.name;
        element.value = element.name;
        this.chaptersOptions.push(element);
      });

      this.lineChartData.length = 0;
      this.lineChartLabels.length = 0;

      event.element.CapitoliGiorno.forEach((element) => {
        this.lineChartData.push(element.ChartData[0]);
        this.lineChartLabels.push(element.ChartLabel[0]);
      });
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    cumulative_lineChartData() {
      let array = [];
      let previousPosition = 0;
      this.lineChartData.forEach((element) => {
        previousPosition += element;
        element = previousPosition;
        array.push(element);
      });
      return array;
    },
  },
  watch: {
    dataSocialShare: {
      deep: true,
      handler() {
        this.sectionsOptions.length = 1;
        this.chaptersOptions.length = 0;
        this.lineChartData.length = 0;
        this.lineChartLabels.length = 0;

        this.loadData();
      },
    },
  },
};
</script>