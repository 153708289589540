<template>
  <div>
    <b-card class="mt-4" v-if="campaignSelected_text == ''">
      <b-tabs content-class="p-4 content-border" nav-class="people-analysis-tabs">
        <b-tab lazy active>
          <template #title>
            <title-pie @CustomEvent_FormChange="changeSelected" :options="sectionsOptions" :selected="sectionSelected"
              :labels="['Completed', 'Total']" :pieData="pieData.sectionSelected" target="sectionSelected"
              :showComponentSelected="false"></title-pie>
          </template>
          <div class="d-flex">
            <!-- Mancano i dati per il Line-chart -->

            <!-- <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="sectionSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + sectionSelected"
            /> -->
          </div>
        </b-tab>
        <b-tab lazy>

          <template #title>
            <title-pie @CustomEvent_FormChange="changeSelected" :options="chaptersOptions" :selected="chapterSelected"
              :labels="['Corrects', 'Answered']" :pieData="pieData.correctAnswers" target="chapterSelected"
              :showComponentSelected="false"></title-pie>
          </template>
          <!-- Mancano i dati per il Line-chart -->
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="chapterSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + chapterSelected"
            />
          </div> -->
        </b-tab>

      </b-tabs>
    </b-card>
    <b-card class="mt-4" v-else>{{ campaignSelected_text }}</b-card>
  </div>
</template>

<script>
// import LineChart from "../LineChart.vue";

import TitlePie from "../DashBoard/TabTitles/TitlePie.vue";
import { mapGetters } from "vuex";

export default {
  name: "LearningComponent",
  components: {
    // LineChart,
    TitlePie,
  },
  props: {
    dataLearning: Array,
    campaignSelected_text: String,
  },
  service: null,
  created() {
    this.view_lang = this.default_lang.value;

    // this.service = new AxiosService("Dashboard/GetLearningData");
    this.dataLearning.forEach((element) => {
      this.sectionsOptions.push(element);
    });
  },

  mounted() {
    // await this.service.create(this.filter).then((res) => {
    //   this.dataLearning = res;
    // });
    this.loadData();
  },
  data() {
    return {
      // dataLearning: null,

      sectionSelected: "All sections",
      sectionsOptions: [{ text: "All sections", value: "All sections" }],

      pieData: {
        sectionSelected: [0, 0],
        chapterSelected: [0, 0],
        correctAnswers: [0, 0],
      },

      chapterSelected: "All chapters",
      chaptersOptions: [{ text: "All chapters", value: "All chapters" }],

      lineChartData: [],
      lineChartLabels: [],

      view_lang: null,
    };
  },

  methods: {
    loadData() {
      this.sectionsOptions.length = 1;

      this.dataLearning.forEach((element) => {
        // Set element.text e element.value per il testo e il valore in b-select dentro il TitlePie
        element.text = element.Name[this.view_lang];
        element.value = element.ID.toString();

        //Calcolo i capitoli totali e i capitoli completati della sezione
        element.capitoliTotali = element.capitoli.length;
        element.capitoliCompletati = 0;
        element.capitoli.forEach((capitolo) => {
          if (capitolo.Completato) {
            element.capitoliCompletati++;
          }
        });

        this.sectionsOptions.push(element);
      });
    },
    changeSelected(event) {
      this[event.target] = event.value;
      console.log("changeSelected(event) event => ", event);

      switch (event.target) {
        case "sectionSelected":
          this.pieData.sectionSelected.length = 0;
          this.pieData.sectionSelected.push(event.element.capitoliCompletati);
          this.pieData.sectionSelected.push(event.element.capitoliTotali);
          this.chaptersOptions.length = 1;
          this.chapterSelected = "All chapters";
          this.pieData.chapterSelected = [0, 0];
          this.pieData.correctAnswers = [0, 0];
          break;
        case "chapterSelected":
          console.log('CONTROLLO CAPITOLI COMPLETATI', event.element.RisposteTotali)
          this.pieData.chapterSelected.length = 0;
          // this.pieData.chapterSelected.push(event.element.domandeRisposte);
          // this.pieData.chapterSelected.push(0);
          // this.pieData.chapterSelected = [event.element.RisposteTotali, event.element.RisposteTotali];
          this.pieData.correctAnswers.length = 0;
          this.pieData.correctAnswers = [event.element.RisposteCorrette, event.element.RisposteTotali];
          // this.pieData.correctAnswers.push(event.element.domandeRisposte);
          // this.pieData.correctAnswers.push(0);
          break;
      }

      if (event.element.capitoli) {
        event.element.capitoli.forEach((element) => {
          element.text = element.Name[this.view_lang];
          element.value = element.ID.toString();
          this.chaptersOptions.push(element);
        });
      }

      this.lineChartData.length = 0;
      this.lineChartLabels.length = 0;

      // event.element.CapitoliGiorno.forEach((element) => {
      //   this.lineChartData.push(element.ChartData[0]);
      //   this.lineChartLabels.push(element.ChartLabel[0]);
      // });
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    cumulative_lineChartData() {
      let array = [];
      let previousPosition = 0;
      this.lineChartData.forEach((element) => {
        previousPosition += element;
        element = previousPosition;
        array.push(element);
      });
      return array;
    },
  },
  watch: {
    dataLearning: {
      deep: true,
      handler() {
        this.sectionsOptions.length = 1;
        this.chaptersOptions.length = 1;
        this.lineChartData.length = 0;
        this.lineChartLabels.length = 0;

        this.loadData();
      },
    },
  },
};
</script>