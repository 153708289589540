import { render, staticRenderFns } from "./SimplePieChart.vue?vue&type=template&id=e10e0304&scoped=true&"
import script from "./SimplePieChart.vue?vue&type=script&lang=js&"
export * from "./SimplePieChart.vue?vue&type=script&lang=js&"
import style0 from "./SimplePieChart.vue?vue&type=style&index=0&id=e10e0304&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e10e0304",
  null
  
)

export default component.exports