<template>
  <div class="d-flex align-items-center">
    <div class="chart-container mr-3">
      <doughnut-chart :labels="labels" :pieData="[computed_pieData[0], computed_pieData[1]]"></doughnut-chart>
      <div v-if="isPercent" class="absolute">
        <p v-if="computed_pieData[0] != 0 && computed_pieData[1] == 0">100%</p>
        <p v-else>
          {{
          (
          (computed_pieData[0] /
          (computed_pieData[1] + computed_pieData[0])) *
          100
          ).toFixed(0)
          }}%
        </p>
      </div>
      <div v-else class="absolute">
        <p>{{ centerNumber }}</p>
      </div>
    </div>
    <div v-if="hasLegend" class="labels">
      <span :style="'color:' + this.primaryColor">{{ legend[0] }} </span>
      /
      <span :style="'color:' + this.secondaryColor"> {{ legend[1] }} </span>
    </div>
    <info-popover v-if="infoPopover" :text="infoPopover"></info-popover>
  </div>
</template>

<script>
import DoughnutChart from "./DoughnutChart.vue";
import InfoPopover from "./InfoPopover.vue";

export default {
  name: "SimplePieChart",
  components: {
    DoughnutChart,
    InfoPopover,
  },
  props: {
    pieData: Array,
    labels: Array,

    centerNumber: Number,
    legend: Array,

    isPercent: {
      type: Boolean,
      default: true,
    },
    hasLegend: {
      type: Boolean,
      default: true,
    },
    infoPopover: String,
  },
  created() {
    const appElement = document.querySelector("#app");
    const style = getComputedStyle(appElement);
    this.primaryColor = style.getPropertyValue("--whappy-primary");
    this.secondaryColor = style.getPropertyValue("--whappy-secondary");
  },
  data() {
    return {
      primaryColor: "",
      secondaryColor: "",
    };
  },
  computed: {
    computed_pieData() {
      if (this.pieData[0] == 0 && this.pieData[1] == 0) {
        return [0, 1];
      } else if (this.pieData[0] != 0 && this.pieData[1] == 0) {
        return [this.pieData[0], 0];
      } else {
        return [this.pieData[0], this.pieData[1] - this.pieData[0]];
      }
    },
  },
};
</script>

<style scoped>
doughnut-chart {
  z-index: 1000;
}
</style>