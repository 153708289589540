<template>
  <div>
    <b-card class="mt-4" v-if="campaignSelected_text == ''">
      <b-tabs
        content-class="p-4 content-border"
        nav-class="people-analysis-tabs"
      >
        <b-tab lazy active>
          <template #title>
            <title-pie
              :labels="['Won', 'Tot']"
              :pieData="pieData.badgeSelected"
              :title="'Badges'"
            ></title-pie>
          </template>
          <div class="d-flex">
            <!-- Mancano i dati per il Line-chart -->

            <!-- <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="sectionSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + sectionSelected"
            /> -->
          </div>
          <h4>Vinti</h4>
          <div v-if="badge_vinti" class="badges-list">
            <div
              v-for="badge in badge_vinti"
              :key="badge.id"
              class="badge-item"
            >
              <b-avatar size="lg" :src="badge.url_image"></b-avatar>
              {{ badge.title[view_lang] }}
            </div>
          </div>
          <div v-else>Non ci sono Badge vinti</div>
          <h4>Non Vinti</h4>
          <div v-if="badge_non_vinti" class="badges-list">
            <div
              v-for="badge in badge_non_vinti"
              :key="badge.id"
              class="badge-item"
            >
              <b-avatar size="lg" :src="badge.url_image"></b-avatar>
              {{ badge.title[view_lang] }}
            </div>
          </div>
          <div v-else>Tutti i badge disponibili sono stati vinti</div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <title-pie
              :labels="['Won', 'Tot']"
              :pieData="pieData.awardSelected"
              :title="'Awards'"
            ></title-pie>
          </template>
          <!-- Mancano i dati per il Line-chart -->
          <h4>Vinti</h4>
          <div v-if="award_vinti" class="awards-list">
            <div
              v-for="award in award_vinti"
              :key="award.id"
              class="award-item"
            >
              <b-avatar size="lg" :src="award.url_image"></b-avatar>
              {{ award.title[view_lang] }}
            </div>
          </div>
          <div v-else>Non ci sono award vinti</div>
          <h4>Non Vinti</h4>
          <div v-if="award_non_vinti" class="awards-list">
            <div
              v-for="award in award_non_vinti"
              :key="award.id"
              class="award-item"
            >
              <b-avatar size="lg" :src="award.url_image"></b-avatar>
              {{ award.title[view_lang] }}
            </div>
          </div>
          <div v-else>Tutti i Award disponibili sono stati vinti</div>
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="chapterSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + chapterSelected"
            />
          </div> -->
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card class="mt-4" v-else>{{ campaignSelected_text }}</b-card>
  </div>
</template>

<script>
// import LineChart from "../LineChart.vue";

import TitlePie from "../DashBoard/TabTitles/TitlePie.vue";
import { mapGetters } from "vuex";

export default {
  name: "BadgeAndAwardsComponent",
  components: {
    // LineChart,
    TitlePie,
  },
  props: {
    dataBadgeAndAwards: Object,
    campaignSelected_text: String,
  },
  service: null,
  created() {
    this.view_lang = this.default_lang.value;
    console.log(this.dataBadgeAndAwards);
  },

  mounted() {
    this.loadData();
  },
  data() {
    return {
      badgesOptions: [],

      awardsOptions: [],

      pieData: {
        badgeSelected: [0, 0],
        awardSelected: [0, 0],
      },

      lineChartData: [],
      lineChartLabels: [],

      view_lang: null,
    };
  },

  methods: {
    loadData() {
      this.badgesOptions.splice(0);
      this.awardsOptions.splice(0);
      this.pieData.badgeSelected.splice(0);
      this.pieData.awardSelected.splice(0);

      let badgesCompletati = 0;
      let awardsCompletati = 0;
      this.dataBadgeAndAwards.badges.forEach((element) => {
        this.badgesOptions.push(element);
        if (element.vinto) {
          badgesCompletati++;
        }
      });
      this.pieData.badgeSelected.push(badgesCompletati);
      this.pieData.badgeSelected.push(this.badgesOptions.length);

      this.dataBadgeAndAwards.awards.forEach((element) => {
        this.awardsOptions.push(element);
        if (element.vinto) {
          awardsCompletati++;
        }
      });
      this.pieData.awardSelected.push(awardsCompletati);
      this.pieData.awardSelected.push(this.awardsOptions.length);
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    cumulative_lineChartData() {
      let array = [];
      let previousPosition = 0;
      this.lineChartData.forEach((element) => {
        previousPosition += element;
        element = previousPosition;
        array.push(element);
      });
      return array;
    },
    badge_vinti() {
      let array = [];
      this.badgesOptions.forEach((element) => {
        if (element.vinto) {
          array.push(element);
        }
      });
      return array;
    },
    badge_non_vinti() {
      let array = [];
      this.badgesOptions.forEach((element) => {
        if (!element.vinto) {
          array.push(element);
        }
      });
      return array;
    },
    award_vinti() {
      let array = [];
      this.awardsOptions.forEach((element) => {
        if (element.vinto) {
          array.push(element);
        }
      });
      return array;
    },
    award_non_vinti() {
      let array = [];
      this.awardsOptions.forEach((element) => {
        if (!element.vinto) {
          array.push(element);
        }
      });
      return array;
    },
  },
  watch: {
    dataBadgeAndAwards: {
      deep: true,
      handler() {
        this.badgesOptions.length = 0;
        this.awardsOptions.length = 0;
        this.lineChartData.length = 0;
        this.lineChartLabels.length = 0;

        this.loadData();
      },
    },
  },
};
</script>